import React, { useState } from 'react';
import { Paragraph } from '@increasecard/typed-components';
import styled, { css } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { Container as BaseContainer } from '../components/Container';
import { Grid } from '../components/Grid';
import { SMALL } from '../breakpoints';
import { CTAButton } from '../components/CTAButton';
import Color from 'color';
import { H1 } from '../components/Typography';

const REMOVE_LAST_SPACE_REGEXP = /(.*)\s([\wáéíóú.]*)$/i;

function replaceLastSpaceWithNbsp(s) {
  const match = REMOVE_LAST_SPACE_REGEXP.exec(s);

  if (match === null) return s;

  const [, start, lastWord] = match;
  return `${start}&nbsp;${lastWord}`;
}

const Container = styled(BaseContainer)`
  padding-top: 90px;
  padding-bottom: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const SectionTitle = styled(H1)`
  margin-bottom: 56px;

  @media (min-width: ${SMALL}) {
    max-width: 10em;
    margin-bottom: 80px;
  }
`;

const ProductCards = styled(Grid)`
  width: 100%;
  align-items: center;
  grid-row-gap: 16px;

  @media (min-width: ${SMALL}) {
    height: 248px;
    grid-auto-flow: row dense;
  }
`;

const ProductCardTitle = styled.h3`
  ${({ theme }) => theme.typography.H1}
  line-height: 40px;
  font-weight: bold;
  margin-bottom: 16px;

  @media (min-width: ${SMALL}) {
    ${({ active, theme }) => !active && theme.typography.H3}
    margin-bottom: 0;
  }
`;

const ProductCardDescription = styled(Paragraph)`
  max-width: 23em;
  @media (min-width: ${SMALL}) {
    ${({ active }) =>
      !active &&
      css`
        font-size: 9px;
        line-height: 11px;
        letter-spacing: 0;
      `};
  }
`;

const ProductCard = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  outline: 0;
  grid-column: span 2 / span 2;
  padding: 44px 16px;
  height: 100%;
  border-radius: 25px;
  color: ${({ theme }) => theme.colorsNew.gray.white};
  box-shadow: ${({ theme }) => theme.shadows.elevate};

  ${CTAButton} {
    margin-top: 16px;
    width: max-content;
    background: ${({ theme, productCodename }) =>
      theme.colorsNew[productCodename].accent};
    :hover {
      box-shadow: 1px 2px 3px
          ${({ theme }) =>
            Color(theme.colorsNew.gray.blackSLight)
              .alpha(0.27)
              .rgb()
              .string()},
        1px 2px 2px
          ${({ theme }) =>
            Color(theme.colorsNew.gray.blackSLight)
              .alpha(0.36)
              .rgb()
              .string()};
    }
  }

  @media (min-width: ${SMALL}) {
    display: flex;

    grid-column: ${({ active, index }) => {
      if (active) {
        return '3 / span 4';
      }
      if (index === 1) {
        return '7 / span 2';
      }
      return 'span 2 / span 2';
    }};

    ${CTAButton} {
      margin-top: 0;
    }
  }

  ${({ active, theme, productCodename, isEnterprise }) => {
    if (active) {
      return css`
        grid-column: span 2 / span 2;
        padding: 44px 16px;
        height: 100%;
        border-radius: 25px;
        background: ${isEnterprise
          ? theme.colorsNew[productCodename].dark
          : theme.colorsNew[productCodename].regular};
        color: ${theme.colorsNew.gray.white};
        box-shadow: ${theme.shadows.elevate};
        .productName {
          color: ${isEnterprise
            ? theme.colorsNew[productCodename].accent
            : theme.colorsNew[productCodename].dark};
        }

        ${CTAButton} {
          background: ${theme.colorsNew[productCodename].accent};
          color: ${theme.colorsNew[productCodename].dark};
        }
      `;
    }
    return css`
      grid-column: span 2 / span 2;
      padding: 44px 16px;
      height: 100%;
      border-radius: 25px;
      background: ${isEnterprise
        ? theme.colorsNew[productCodename].dark
        : theme.colorsNew[productCodename].regular};
      color: ${theme.colorsNew.gray.white};
      box-shadow: ${theme.shadows.elevate};
      .productName {
        color: ${isEnterprise
          ? theme.colorsNew[productCodename].accent
          : theme.colorsNew[productCodename].dark};
      }

      ${CTAButton} {
        background: ${theme.colorsNew[productCodename].accent};
        color: ${theme.colorsNew[productCodename].dark};
      }
      @media (min-width: ${SMALL}) {
        padding: 20px 16px;
        background: ${theme.colorsNew.gray.grayLight};
        color: ${theme.colorsNew.gray.blackSLight};
        box-shadow: ${theme.shadows.rest};
        height: 50%;
        border-radius: 12.5px;

        .productName {
          color: unset;
        }

        ${CTAButton} {
          background: ${theme.colorsNew.gray.blackSLight};
          color: ${({ theme }) => theme.colorsNew.gray.white};
          font-size: 9px;
          padding: 0 8px;
          line-height: 11px;
          height: 22px;
        }
      }
    `;
  }}
`;

export function OtherSolutionsSection({ product, onlyEnterprise }) {
  const [activeProduct, setActiveProduct] = useState(0);
  const query = useStaticQuery(graphql`
    query {
      strapi {
        products(where: { visible: true } ) {
          codename
          name
          description
          enterpriseAvailable
          countries {
            name
          }
        }
      }
    }
  `);
  const products = query.strapi.products.filter(
    ({ codename, enterpriseAvailable }) => {
      if (['increasecard', 'increasepay'].includes(codename)) return false;
      if (onlyEnterprise) {
        return codename !== product.codename && enterpriseAvailable;
      }
      return codename !== product.codename;
    }
  );
  return (
    <Container>
      <SectionTitle>Otras soluciones para potenciar tu negocio</SectionTitle>
      <ProductCards>
        {products.map(({ codename, name, description }, index) => {
          return (
            <ProductCard
              onClick={() => setActiveProduct(index)}
              isEnterprise={onlyEnterprise}
              key={codename}
              productCodename={codename}
              active={index === activeProduct}
              index={index}
            >
              <ProductCardTitle active={index === activeProduct}>
                Increase{' '}
                {onlyEnterprise ? (
                  <>
                    {name}
                    <em className="productName"> enterprise</em>
                  </>
                ) : (
                  <span className="productName">{name}</span>
                )}
              </ProductCardTitle>
              <ProductCardDescription
                active={index === activeProduct}
                dangerouslySetInnerHTML={{
                  __html: replaceLastSpaceWithNbsp(description),
                }}
              ></ProductCardDescription>
              <CTAButton
                text={`Conoce ${name}`}
                href={`/${
                  onlyEnterprise ? 'enterprise' : 'productos'
                }/${codename}`}
              />
            </ProductCard>
          );
        })}
      </ProductCards>
    </Container>
  );
}
