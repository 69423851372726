import React from 'react';
import styled from 'styled-components';
import { H3 } from '@increasecard/typed-components';

function getPalette(variant) {
  switch (variant) {
    case 'white_light30':
      return {
        box: {
          bgColor: ({ theme }) => theme.colorsNew.gray.white,
          color: ({ theme }) => theme.colorsNew.product.light30,
        },
        cta: {
          bgColor: ({ theme }) => theme.colorsNew.product.light30,
          color: ({ theme }) => theme.colorsNew.gray.white,
        },
        description: {
          color: ({ theme }) => theme.colorsNew.gray.black,
        },
      };
    case 'white_dark50':
      return {
        box: {
          bgColor: ({ theme }) => theme.colorsNew.gray.white,
          color: ({ theme }) => theme.colorsNew.product.dark50,
        },
        cta: {
          bgColor: ({ theme }) => theme.colorsNew.product.dark30,
          color: ({ theme }) => theme.colorsNew.gray.white,
        },
        description: {
          color: ({ theme }) => theme.colorsNew.gray.black,
        },
      };
    case 'dark30_accent':
      return {
        box: {
          bgColor: ({ theme }) => theme.colorsNew.product.dark30,
          color: ({ theme }) => theme.colorsNew.gray.white,
        },
        cta: {
          bgColor: ({ theme }) => theme.colorsNew.product.dark70,
          color: ({ theme }) => theme.colorsNew.gray.white,
        },
        title: {
          extraStyles: ({ theme }) =>
            `color: ${theme.colorsNew.product.accent};`,
        },
      };
    case 'black_accent':
    default:
      return {
        box: {
          bgColor: ({ theme }) => theme.colorsNew.gray.black,
          color: ({ theme }) => theme.colorsNew.gray.white,
        },
        cta: {
          bgColor: ({ theme }) => theme.colorsNew.product.dark,
          color: ({ theme }) => theme.colorsNew.gray.white,
        },
        title: {
          extraStyles: ({ theme }) =>
            `color: ${theme.colorsNew.product.accent};`,
        },
      };
  }
}

const Wrapper = styled.a`
  position: relative;
  border-radius: 22px;
  background: ${({ bgColor }) => bgColor};
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: ${({ color }) => color};
  padding-top: 32px;
  height: 272px;
  box-shadow: ${({ theme }) => theme.shadows.elevate};
`;

const Title = styled.div`
  font-size: 16px;
  line-height: 31px;
  font-weight: 800;
  margin-bottom: 16px;
  letter-spacing: 0.03rem;
  ${({ extraStyles }) => extraStyles}
`;

const Price = styled.p`
  font-size: 26px;
  line-height: 35px;
  letter-spacing: 0.03rem;
  margin-bottom: 20px;
  font-weight: 800;
`;

const SmallText = styled(H3)`
  color: ${({ color }) => color};
  padding: 0 16px;
  font-size: 20px;
  font-weight: 600;

  small {
    font-weight: 400;
    font-size: 16px;
  }
`;

const Cta = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  font-weight: bold;
  height: 40px;
  border-radius: 0 0 22px 22px;
  background: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
`;

export function PricingOption({
  title,
  price,
  priceDescriptionLarge,
  priceDescriptionSmall,
  ctaHref,
  ctaText,
  colorScheme,
}) {
  const palette = getPalette(colorScheme);
  return (
    <Wrapper {...palette.box} href={ctaHref}>
      <div>
        <Title {...palette.title}>{title}</Title>
        {price && <Price dangerouslySetInnerHTML={{ __html: price }} />}
        <SmallText {...palette.description}>
          <p style={{ marginBottom: '6px' }}>{priceDescriptionLarge}</p>
          <p>
            <small>{priceDescriptionSmall}</small>
          </p>
        </SmallText>
      </div>
      <Cta href={ctaHref} {...palette.cta}>
        {ctaText}
      </Cta>
    </Wrapper>
  );
}
